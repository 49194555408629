<template>
  <div>
    <v-container>
      <v-row justify="center" align-content="center">
        <v-col cols="12" md="5" sm="6" class="hidden-sm-and-down">
          <v-card class="mt-md-10 elevation-0">
            <v-card-title>
              <v-img
                  :src="enrollImage"
                  height="100%"
                  width="100%">
              </v-img>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" sm="6">
          <v-card class="my-md-10 elevation-0">
            <v-card-title class="pb-0 mb-0 text-h5 font-weight-bold">
              <v-btn @click="back" class="me-4" icon><v-icon class="mr-1" color="orange">mdi-arrow-left</v-icon> Back</v-btn>
            </v-card-title>
            <v-card-title class="text-h6 font-weight-bold d-flex">
              Registrasi Akun Baru,
              <div class="ml-1 text-h5 font-weight-bold" :class="`teal--text`" >
                ASN Luar Daerah
              </div>
            </v-card-title>
            <v-card-subtitle class="grey--text font-weight-light">
              Untuk ASN Luar Daerah yang ingin mengajukan pindah ke dalam Lingkungan Pemerintah Kota Palangkaraya
            </v-card-subtitle>
            <v-stepper v-model="step" class="elevation-0">
              <v-stepper-header class="elevation-0 px-md-16">
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 1" step="1"
                >Registrasi</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">Verifikasi</v-stepper-step>
                <v-divider></v-divider>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form v-model="valid" ref="form">

                    <v-card-text>
                      <v-row class="my-0 py-0">
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              class="my-0 py-0"
                              prepend-inner-icon="mdi-account"
                              v-model="user.username"
                              :rules="[validateNotEmpty]"
                              required
                              dense
                              placeholder="NIP anda"
                              outlined
                              label="NIP (ID Pengguna Baru)"
                              type="text"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              class="my-0 py-0"
                              dense
                              outlined
                              v-model="user.email"
                              label="Email"
                              :rules="[validateNotEmpty, validateEmail]"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="my-0 py-0">
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              class="my-0 py-0"
                              prepend-inner-icon="mdi-whatsapp"
                              v-model="user.phone"
                              :rules="[validatePhoneNumber]"
                              required
                              dense
                              placeholder="08.."
                              outlined
                              label="WhatsApp"
                              type="text"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="my-0 py-0 mt-2">
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              v-model="user.firstName"
                              class="my-0 py-0"
                              dense
                              outlined
                              label="Nama Depan"
                              :rules="rulesNotEmtpy"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              v-model="user.lastName"
                              class="my-0 py-0"
                              dense
                              outlined
                              label="Nama Belakang"
                              :rules="rulesNotEmtpy"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="my-0 py-0"  v-if="false">
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-autocomplete
                              class="my-0 py-0"
                              v-model="user.countryCode"
                              :items="itemsFCountry"
                              :rules="rulesNotEmtpy"
                              item-value="id"
                              item-text="kode1"
                              auto-select-first
                              dense
                              chips
                              outlined
                              deletable-chips
                              color="blue-grey lighten-2"
                              label="Country"
                              persistent-hint
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row class="my-0 py-0">
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              v-model="user.password"
                              label="Password"
                              dense
                              outlined
                              :rules="[required, rulesMinLength]"
                              @click:append="showPassword = !showPassword"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPassword ? 'text' : 'password'"
                              clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                          <v-text-field
                              v-model="user.retypePassword"
                              label="Password"
                              dense
                              outlined
                              :rules="[required, rulesMinLength]"
                              @click:append="showRetypePassword = !showRetypePassword"
                              :append-icon="showRetypePassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showRetypePassword ? 'text' : 'password'"
                              clearable
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="my-0 py-0" v-if="false">
                        <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                          <v-autocomplete
                              class="my-0 py-0"
                              v-model="user.fdivisionBean"
                              :items="itemsFDivision"
                              :rules="rulesNotEmtpy"
                              item-value="id"
                              item-text="description"
                              auto-select-first
                              dense
                              chips
                              outlined
                              deletable-chips
                              color="blue-grey lighten-2"
                              label="Dinas"
                              persistent-hint
                              hint="Untuk ASN dari luar Kota Palangka Raya, silahkan memilih *Luar Kota Palangka Raya*"
                          ></v-autocomplete>
                        </v-col>
                        <v-col v-if="false" cols="12" md="12" sm="12" class="my-0 py-0">
                          <v-text-field
                              class="my-0 py-0"
                              dense
                              outlined
                              v-model="user.companyName"
                              label="Nama Organisasi Perusahaan/Instansi"
                              :rules="[validateNotEmpty]"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row v-if="false">
                        <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                          <v-autocomplete
                              v-model="user.sectorsInterst"
                              class="my-0 py-0"
                              :items="itemsFtSectorType"
                              item-value="id"
                              item-text="description"
                              auto-select-first
                              dense
                              chips
                              outlined
                              deletable-chips
                              color="blue-grey lighten-2"
                              label="Sectors you may interest"
                              multiple
                              persistent-hint
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row class="mt-6">
                        <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                          <v-checkbox
                              v-model="privacyPolicy"
                              dense
                              hide-details
                          >
                            <template v-slot:label>
                              <span>I agree to all the <span class="pink--text lighten-3">Terms</span> and <span class="pink--text lighten-3">Privacy Policies</span></span>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row class="mt-8"></v-row>
                      <v-row v-if="errorMessage" class="text-center">
                        <v-col class="subtitle-2 red--text">
                          {{ errorMessage }}
                          <v-icon small color="red">mdi-thumb-down</v-icon>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                          <v-btn @click="nextStep" :disabled="!valid || privacyPolicy===false" block color="teal" class="white--text">
                            Lanjutkan
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="mt-8 text-center">
                        <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                          <div>Already have account? <router-link class="no-underline" to="/login">
                            <span class="pink--text">Login here</span>
                          </router-link></div>
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card-text>
                    <div class="camera-face">
                      <div class="video-container">
                        <video ref="video" autoplay muted></video>
                        <canvas ref="canvas"></canvas>
                      </div>
                    </div>
                    <v-card class="mx-md-8 mb-4 mt-4 border-green" outlined :class="blinkStatus? 'border-green' : 'border-grey'">
                      <div class="d-flex flex-row mx-4 py-2">
                        <div>
                          <div class="font-weight-bold text-h6" :class="blinkStatus? 'green--text' : 'grey--text'">1. Kedipkan Mata</div>
                          <div class="font-weight-light grey--text text-caption">Lepaskan kacamata untuk mempermudah</div>
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon :color="blinkStatus? 'green' : 'grey'">mdi-check-circle</v-icon>
                      </div>
                    </v-card>
                    <v-card class="mx-md-8 mb-4" outlined :class="mouthStatus? 'border-green' : 'border-grey'">
                      <div class="d-flex flex-row mx-4 py-2">
                        <div>
                          <div class="font-weight-bold text-h6" :class="mouthStatus? 'green--text' : 'grey--text'">2. Buka Mulut</div>
                          <div class="font-weight-light grey--text text-caption">Buka mulut hingga terdeteksi</div>
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon :color="mouthStatus? 'green' : 'grey'">mdi-check-circle</v-icon>
                      </div>
                    </v-card>
                    <v-row justify="center" class="mb-6 mt-4">
                      <v-btn v-if="verifStatus === 0" color="green" class="white--text" @click="startDetection" :disabled="!modelsLoaded && video === null">Mulai Verifikasi</v-btn>
                      <div v-if="verifStatus !== 0" class="font-weight-bold text-h6" :class="getTextColor(verifStatus)">{{verifText}}</div>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-row v-if="errorMessage" class="text-center">
                      <v-col class="subtitle-2 red--text">
                        {{ errorMessage }}
                        <v-icon small color="red">mdi-thumb-down</v-icon>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-btn @click="stepBack" color="grey" outlined>Kembali</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="handleSignUp" :disabled="!blinkStatus && !mouthStatus" color="teal" class="white--text">Register</v-btn>
                    </v-row>
                  </v-card-text>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="loading"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="loadingSignup"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import User from "@/models/user";
import ERole from "@/models/e-role";
import FDivisionService from "@/services/apiservices/f-division-service";
import FtRegistrationRegService from "@/services/apiservices-account-registration/ft-registration-reg-service";
import FtRegistrationReq from "@/models/account-registration/ft-registration-req";
import * as faceapi from "face-api.js";

export default {
  name: "UserRegistration",
  components: {},
  data() {
    return {
      step: 1,
      privacyPolicy: false,
      typeAccount: '',
      itemsTypeAccount:[
        {id:1, description:'ASN Kota Palangka Raya', notes:'Untuk ASN atau Pegawai di Lingkungan Kota Palangka Raya', color:"amber", icon:'mdi-account-tie'},
        {id:2, description:'ASN Luar Daerah', notes:'Untuk ASN Luar Daerah yang ingin mengajukan pindah ke dalam Lingkungan Pemerintah Kota Palangkaraya', color:"teal", icon:'mdi-account-convert'},
        // {id:3, description:'Forum Jakonku', notes:'Forum khusus bina konstruksi Prov Kalteng', color:"indigo", icon:'mdi-forum'},
      ],
      showRetypePassword: false,
      showPassword: false,
      confirmPassword: '',
      valid: false,
      rulesNotEmtpy: [(v) => !!v || "must contain a value"],
      validateNotEmpty: (value) => !!value || "must contain a value",
      validateEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value) || "Wrong email format";
      },

      validatePhoneNumber: (value) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(value) || "Only number allowed";
      },
      rulesMinLength: (v) => {
        return (v && v.length >=5) || "Password must be more than 5 character";
      },

      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,
      enrollImage: require("@/assets/images/register-pict.png"),
      itemsFDivision: [],
      user: new User("", ""),
      loading: false,
      loadingSignup: false,
      snackbar: false,
      errorMessage: "",
      success: false,
      registration_type: '',
      itemsFCountry:[],
      itemsFtSectorType: [],
      itemsRoles: [ERole.ROLE_USER],



      //DATA FACE RECOGNITION
      modelsLoaded: false,
      video: null,
      photoDescriptor: null,
      uploadedImage: null,
      similarity: null,

      blinkStatus: false,
      blinkCounter: 0,

      orientationStatus: null,
      frontFaceDetected: false,
      sideFaceDetected: false,
      orientationSuccess: null,

      mouthStatus: false,

      verifText: "",

      // 0 = Belum Start,
      // 1 = Mulai,
      // 2 = Verifikasi
      verifStatus: 0
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async nextStep(){
      this.step = 2
      try{
        await this.loadModels();
        this.startCamera();
      } catch (e){
        console.log(e)
      }
    },
    stepBack(){
      this.step = 1
      this.stopCamera()
      this.modelsLoaded = false
      this.video = null
      this.blinkStatus = false
      this.blinkCounter = 0
      this.mouthStatus = false
      this.verifText = ""
      this.verifStatus = 0

    },
    handleSignUp() {
      if (this.user.password !== this.user.retypePassword) {
        this.errorMessage = "Password not match";
        this.snackbar = true;
        return;
      }

      if (this.$refs.form.validate()) {

        this.success = false;

        let ftRegistrationReq = new FtRegistrationReq();
        ftRegistrationReq.timeStart = new Date().toISOString()
        ftRegistrationReq.timeEnd = new Date().toISOString()


        // if(this.registration_type === '1'){
        //   ftRegistrationReq.roleName = ERole.ROLE_USER;
        // } else if(this.registration_type === '2'){
          ftRegistrationReq.roleName = ERole.ROLE_USER;
        // }

        ftRegistrationReq.firstName = this.user.firstName;
        ftRegistrationReq.lastName = this.user.lastName;
        ftRegistrationReq.fullName = `${this.user.firstName} ${this.user.lastName}`;
        ftRegistrationReq.email = this.user.email;
        ftRegistrationReq.password = this.user.password;
        ftRegistrationReq.fdivisionBean = this.itemsFDivision[0].id
        // ftRegistrationReq.isAccountNonLocked = false

        ftRegistrationReq.username = this.user.username;
        // ftRegistrationReq.whatsApp = Number(this.user.username);
        ftRegistrationReq.whatsApp = Number(this.user.phone);

        ftRegistrationReq.countryCode = this.user.countryCode;
        //Bisa dimatikan atau di hidupkan
        if (this.user.countryCode===0){
          ftRegistrationReq.countryCode = 62;
        }

        ftRegistrationReq.type = 1; //1. User Registration 2. Change Password
        /**
         * 0 = email
         * 1 = Whatsapp
         * 2 = SMS
         */
        ftRegistrationReq.tokenMedia = 1;

        ftRegistrationReq.remarkJson = {
          companyName: this.lookupFDivision(this.user.fdivisionBean).description,
          department: '',
          // sectorsInterest: this.user.sectorsInterst
        }

        this.stopCamera()
        this.loadingSignup = true
        FtRegistrationRegService.createFtRegistrationReqPublic(ftRegistrationReq).then(
            response => {
              this.loadingSignup = false
              response.toLocaleString()
              this.$store.dispatch('registration/setItemFtReg', response.data);
              this.$router.push("/registration-masukkan-otp");
            },
            (error) => {
              console.log(error);
              this.loadingSignup = false
              this.errorMessage = `${error.response.data.message}. Fail create ${this.user.username}`;

            }
        );


      }

    },
    back(){
      this.$router.push("/select-account-type")
    },
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMMM yyyy") : "";
    },

    fetchParent() {
      FDivisionService.getAllFDivisionPublic().then((response) => {
        let luarDaerah = 'luar'
          this.itemsFDivision = response.data.filter(
              (item) => item.statusActive === true && item.description.toUpperCase().includes(luarDaerah.toUpperCase()) &&
                  ! item.description.toUpperCase().includes("BIDANG")
          );
      });

    },

    required: function(value) {
      if (value!==undefined) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    lookupRegistrationTypeAccount(id) {
      let result = this.itemsTypeAccount.filter(x => x.id === parseInt(id))
      if (result.length > 0) {
        return result[0]
      }else {
        return ''
      }
    },

    fetchFCountry() {
      fetch('https://restcountries.com/v3.1/all?fields=name,idd,cca2,cca3,region,subregion,timezones,languages')
          .then(response => response.json())
          .then(data => {
            // const countryList = document.getElementById('country-list');
            data.forEach(country => {


              let name = country.name.common;
              let description = country.name.official;
              let region = country.region;

              let countryCode = parseInt((country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')).replace('+', ''), 10);
              this.itemsFCountry.push({id: countryCode, countryCode: countryCode, kode1: name, kode2:country.cca2, description: description,
                region: region,subregion:country.subregion, timeZone: country.timezones, languages: country.languages
              })

            });
          })
          .catch(error => console.error('Error:', error));
    },
    getCountryName(countryCode) {
      const country = this.itemsFCountry.find(c => c.kode2 === countryCode);
      return country ? country.id : null;
    },
    getLocalInfo() {
      fetch('https://ipapi.co/json/')
          .then(response => response.json())
          .then(data => {

            this.user.countryCode = this.getCountryName(data.country_code);

          })
          .catch(error => console.error('Error:', error));
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    getTextColor(id) {
      if (id === 1) return "orange--text";
      else if (id === 2) return "blue--text";
      else if (id === 3) return "green--text";
      else return "gray";
    },



    //LOGIC FACE RECOGNITION
    async loadModels() {
      try {
        const modelPath = process.env.BASE_URL + "assets/models"; // Correct model path
        await faceapi.nets.ssdMobilenetv1.loadFromUri(modelPath);
        await faceapi.nets.faceLandmark68Net.loadFromUri(modelPath);
        await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath);
        this.modelsLoaded = true;
        console.log("Models loaded!");
      } catch (error) {
        console.error("Error loading models:", error);
      }
    },
    stopCamera() {
      if (this.video && this.video.srcObject) {
        const stream = this.video.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(track => track.stop()); // Stop all tracks (video/audio)

        this.video.srcObject = null; // Clear the video element's source
      }
    },
    startCamera() {
      navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            this.video = this.$refs.video;
            this.video.srcObject = stream;

            this.video.addEventListener("loadedmetadata", () => {
              const canvas = this.$refs.canvas;
              canvas.width = this.video.videoWidth;
              canvas.height = this.video.videoHeight;
            });
          })
          .catch((err) => {
            console.error("Error accessing camera:", err);
          });
    },
    async handleImageUpload(event) {
      const imageFile = event.target.files[0];
      const image = await faceapi.bufferToImage(imageFile);
      this.uploadedImage = URL.createObjectURL(imageFile);

      const detections = await faceapi
          .detectSingleFace(image)
          .withFaceLandmarks()
          .withFaceDescriptor();

      if (detections) {
        this.photoDescriptor = detections.descriptor;
        console.log("Uploaded photo descriptor:", this.photoDescriptor);
      }
    },
    async startDetection() {
      this.verifStatus = 1
      this.verifText = "Tunggu Sebentar!!"
      this.loading = true
      if (!this.modelsLoaded) {
        alert("Wait for the models to load.");
        return;
      }
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const displaySize = { width: video.videoWidth, height: video.videoHeight };
      faceapi.matchDimensions(canvas, displaySize);

      const detectFaces = async () => {
        try {
          const detections = await faceapi
              .detectAllFaces(video, new faceapi.SsdMobilenetv1Options())
              .withFaceLandmarks()
              .withFaceDescriptors();

          const resizedDetections = faceapi.resizeResults(detections, displaySize);
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          faceapi.draw.drawDetections(canvas, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

          resizedDetections.forEach((detection) => {
            this.loading = false
            this.verifStatus = 2
            this.verifText = "Silahkan Ikuti Langkah Langkah di atas!"
            // if (this.photoDescriptor) {
            //   const distance = faceapi.euclideanDistance(this.photoDescriptor, detection.descriptor);
            //   this.similarity = (1 - distance).toFixed(2);
            //
            //   if (distance < 0.6) {
            //     faceapi.draw.drawDetections(canvas, [detection]);
            //   }
            // }

            // Detect Blink
            this.detectBlink(detection.landmarks);

            // Detect Orientation
            // this.detectOrientation(detection.landmarks);

            // Detect Mouth Open
            if(this.blinkStatus){
              this.detectMouthOpen(detection.landmarks);
            }
          });
        } catch (error) {
          console.error("Error during face detection:", error);
        }
        requestAnimationFrame(detectFaces);
      };

      detectFaces();
    },
    async startDetectionX() {
      if (!this.modelsLoaded || !this.photoDescriptor) {
        alert("Please upload a photo first and wait for the models to load.");
        return;
      }
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const displaySize = { width: video.videoWidth, height: video.videoHeight };
      faceapi.matchDimensions(canvas, displaySize);

      const detectFaces = async () => {
        try {
          const detections = await faceapi
              .detectAllFaces(video, new faceapi.SsdMobilenetv1Options())
              .withFaceLandmarks()
              .withFaceDescriptors();

          const resizedDetections = faceapi.resizeResults(detections, displaySize);
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          faceapi.draw.drawDetections(canvas, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

          resizedDetections.forEach((detection) => {
            if (this.photoDescriptor) {
              const distance = faceapi.euclideanDistance(this.photoDescriptor, detection.descriptor);
              this.similarity = (1 - distance).toFixed(2);

              if (distance < 0.6) {
                faceapi.draw.drawDetections(canvas, [detection]);
              }
            }

            // Detect Blink
            this.detectBlink(detection.landmarks);

            // Detect Orientation
            this.detectOrientation(detection.landmarks);

            // Detect Mouth Open
            this.detectMouthOpen(detection.landmarks);
          });
        } catch (error) {
          console.error("Error during face detection:", error);
        }
        requestAnimationFrame(detectFaces);
      };

      detectFaces();
    },
    detectBlink(landmarks) {
      const leftEye = landmarks.getLeftEye();
      const rightEye = landmarks.getRightEye();
      const leftEyeEAR = this.getEyeAspectRatio(leftEye);
      const rightEyeEAR = this.getEyeAspectRatio(rightEye);

      const blinkThreshold = 0.2;
      if (leftEyeEAR < blinkThreshold || rightEyeEAR < blinkThreshold) {
        this.blinkCounter++;
        if (this.blinkCounter >= 1) {
          this.blinkStatus = true
        }
      }
    },
    getEyeAspectRatio(eye) {
      const a = this.distance(eye[1], eye[5]);
      const b = this.distance(eye[2], eye[4]);
      const c = this.distance(eye[0], eye[3]);
      return (a + b) / (2.0 * c);
    },
    distance(p1, p2) {
      return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
    },
    detectOrientation(landmarks) {
      const mouthLeft = landmarks.getMouth()[0];
      const mouthRight = landmarks.getMouth()[6];
      const eyeLeft = landmarks.getLeftEye()[0];
      const eyeRight = landmarks.getRightEye()[3];

      const mouthWidth = this.distance(mouthLeft, mouthRight);
      const eyeWidth = this.distance(eyeLeft, eyeRight);

      const angle = (mouthWidth - eyeWidth) / eyeWidth;
      if (Math.abs(angle) < 0.1 && !this.frontFaceDetected) {
        this.orientationStatus = "Front Face Detected!";
        this.frontFaceDetected = true;
        this.sideFaceDetected = false;
      } else if (Math.abs(angle) >= 0.2 && !this.sideFaceDetected) {
        this.orientationStatus = "Side Face Detected!";
        this.sideFaceDetected = true;
        this.frontFaceDetected = false;
      }
      if (this.frontFaceDetected && this.sideFaceDetected) {
        this.orientationSuccess = "Success! Both Front and Side faces detected.";
      }
    },
    detectMouthOpen(landmarks) {
      const mouth = landmarks.getMouth();
      const upperLip = mouth[13];
      const lowerLip = mouth[19];
      const mouthLeft = mouth[0];
      const mouthRight = mouth[6];

      const verticalDistance = this.distance(upperLip, lowerLip);
      const horizontalDistance = this.distance(mouthLeft, mouthRight);

      const MAR = verticalDistance / horizontalDistance;
      const mouthOpenThreshold = 0.8;

      if (MAR > mouthOpenThreshold) {
        this.mouthStatus = true;
      }
    },
  },
  mounted() {
    this.fetchParent();
    // this.registration_type = this.$route.query.registration_type

    this.fetchFCountry()
    this.getLocalInfo()

  },
};
</script>
<style scoped>
.border-green{
  border: 3px solid #4CAF50;
}

.border-grey{
  border: 1px solid #E0E0E0;
}

.no-underline {
  text-decoration: none;
}
.camera-face {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  position: relative;
  width: 300px; /* Set the width of the circular video */
  height: 300px; /* Set the height equal to width for a perfect circle */
  border-radius: 50%; /* Make the container circular */
  overflow: hidden; /* Hide any content outside the circular boundary */
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video fits nicely within the circle */
  border-radius: 50%; /* Apply circular shape to the video */
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* Hide the canvas */
}
</style>


